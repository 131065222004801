import React, { useState } from "react"
import SEO from "../components/seo"
import { Link, StaticQuery, graphql } from "gatsby"
import { withStyles } from "@material-ui/core/styles"

import FormControl from "@material-ui/core/FormControl"
import FormHelperText from "@material-ui/core/FormHelperText"
import TextField from "@material-ui/core/TextField"
import Button from "@material-ui/core/Button"

import MailChimpForm from "../components/MailchimpForm"


import "../landing.css"

export default function LandingPage() {
  return (
    <div style={{ background: "#fafafa", height: "100%" }}>
      <section style={{ background: "#fafafa", height: "100%" }}>
        <div className="container">
          <div className="col-6 details">
            <h3>About</h3>
       
    
            <p>
              I love how much good geospatial content there is on YouTube and I felt like it organizing it in a filtering interface.
            </p>
          

            <p>
              If you have suggestions, questions, comments or want to submit a
              video, please tweet at me <a href="https://twitter.com/KyleAPennell">@kyleapennell</a>.
            </p>
          </div>
          <div className="col-6 text--left">

          <p>
              If you wish to follow this project and get notified when I add more videos, please feel free to join this mailchimp list.
            </p>

            <MailChimpForm />




          </div>
        </div>
      </section>
    </div>
  )
}
