import addToMailchimp from "gatsby-plugin-mailchimp"
import React, { useState } from "react"
import Checkbox from "@material-ui/core/Checkbox"
import TextField from "@material-ui/core/TextField"
import InputAdornment from "@material-ui/core/InputAdornment"
import Email from "@material-ui/icons/Email"
import Button from "@material-ui/core/Button"

const SignupForm = () => {
  const [email, setEmail] = useState()
  const [gdpr_manual, setCheckBoxManual] = useState()
  const [message, setMessage] = useState()

  const handleSubmit = async event => {
    event.preventDefault()
    setMessage("Subscribing..." + email)
    const response = await addToMailchimp(email)

    if (response.result === "error") {
      if (response.msg.toLowerCase().includes("already subscribed")) {
        setMessage("Subscribed")
      } else {
        setMessage("woops! Something went wrong")
      }
    } else {
      setMessage("Subscribe.")
      window.location.href = "/about"
    }
  }

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <TextField
          onChange={event => setEmail(event.target.value)}
          id="filled-basic"
          label="Email"
          variant="outlined"
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Email />
              </InputAdornment>
            ),
            endAdornment: (
              <Button type="submit" variant="outlined" color="primary">
                Subscribe
              </Button>
            ),
          }}
        />
        <br />
  
        <div>
          <p>{message}</p>
        </div>
      </form>
    </div>
  )
}

export default SignupForm
